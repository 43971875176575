.certSize{
  margin-right: auto!important;
  margin-left: auto!important; 
  margin-top: auto!important;
  margin-bottom: auto!important;
}



@media (max-width: 600px) {
  .certSize{
    height: 250px!important;
    width: 350px!important;
  }
  .slide2None{
    display: none;
  }
  .certContainer div.swiper-wrapper{
    height: 350px;
  }
  .paddingTopImgQualificacao{
    padding-top: 30px;
  }
}


@media (min-width: 601px) and (max-width:960px)  {
  
  .slide1None{
    display: none;
  }
  .certSize{
    height: 210px!important;
    width: 280px!important;
  }
  
  .certContainer div.swiper-wrapper{
    height: 270px;
  }
  .paddingTopImgQualificacao{
    padding-top: 30px;
  }
}
@media (min-width: 961px) {
   .certSize{
      height: 250px;
      width: 350px;
   }
  .slide1None{
    display: none;
  }
  .certContainer div.swiper-wrapper{
    height: 270px;
  }
  .swiper-pagination-bullet {
  border: solid;
  border-color: var(--color-primary-gray);
  border-width: 3px;
  }
}
