




@media (max-width: 600px) { 
  
  .swiper2None{
    display: none;
  }
  .swiper3None{
    display: none;
  }
  
  .devSkills div.swiper-wrapper  {
    height: 340px;
  }  
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    border-radius: 5%;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: block;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-pagination-bullets {
    bottom: -2px !important;
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border: solid;
    border-color: black;
  }
  .swiper-slide {
  
    align-items: unset;
    background-color: transparent;
  }
  

}





@media (min-width: 601px) and (max-width:800px)  { 
  .swiper1None{
    display: none;
  }
  .swiper3None{
    display: none;
  }

  .devSkills div.swiper-wrapper {
    height: 340px;
  }  

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 5%;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: block;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-bullets {
  bottom: -2px !important;
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border: solid;
  border-color: black;
}
.swiper-slide {

  align-items: unset;
  background-color: transparent;
}

}
@media (min-width: 801px) and (max-width:960px)  { 
  .swiper1None{
    display: none;
  }
  .swiper2None{
    display: none;
  }
  
  .devSkills div.swiper-wrapper  {
    height: 340px;
  }  

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    border-radius: 5%;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: block;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-pagination-bullets {
    bottom: -2px !important;
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border: solid;
    border-color: black;
  }
  .swiper-slide {
    align-items: unset;
    background-color: transparent;
  }

}