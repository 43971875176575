.paddingTopDistance{
  padding: 20px 0;
  background-color: var(--color-primary-gray);
}

@media (max-width: 768px) {
  .fontName{
    font-weight: bold !important;
    font-family: 'roboto-mono', monospace !important;
    color: var(--color-primary-gray)!important;
  }
  .fontOcupation{
    font-weight: bold !important;
    font-family: 'roboto-mono', monospace !important;
    color: var(--color-primary-gray)!important;
  }
  

  
}

@media (min-width: 769px) { 
  /* durante o desenvolvimento do site eu alterei uma classe root para deixar a side bar toda com a cor verde, 
  porem isso afetou esse bloco e por esse motivo eu criei essa classe */
  .fontName{
    font-weight: bold !important;
    font-family: 'roboto-mono', monospace !important;
    color: var(--color-primary-gray)!important;
  }
  .fontOcupation{
    font-weight: bold !important;
    font-family: 'roboto-mono', monospace !important;
    color: var(--color-primary-gray)!important;
  }

}