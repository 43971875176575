.distance{
  padding-bottom: 25px;
}
div.imgPadding:nth-child(1){
  padding:18px 0 ;
}
alignServices{
  padding-bottom: 100px!important;
}
.developingWH{
  width: 295px !important;
  height: 230px !important;
}
.maintenanceWH{
  width: 330px !important;
  height: 230px !important;
}
.networkWH{
  width: 270px !important;
  height: 230px !important;
}
@media (max-width: 768px) { 
  
}
@media (min-width: 769px) { 
    

}

