.backgroundAvatar{
  background: rgb(33,33,33);
  background: linear-gradient(0deg, rgba(33,33,33,1) 25%, rgba(33,33,33,0) 100%);
}
.MuiAvatar-colorDefault {
  border-top: var(--color-green);
  border-left: var(--color-green);
  border-bottom: var(--color-green) ;
  border-right:var(--color-green) ;
  border-style: solid;
  border-width: 2px;
}
.alignAvatar{
  margin-left: auto;
  margin-right: auto;
}