:root{
    --color-white:#ffff;
    --color-green:#62E093;
    --color-dark-green: #429e66;
    --color-primary-gray:#212121;
    --color-sidebar-gray:#3c3c3c;
    --color-footer-gray:#141414;
    --font-primary: 'roboto-mono', monospace;
    --font-secundary: 'roboto', monospace;
    --size-avatar:200px;
    --swiper-theme-color: #62E093 !important;
    --width-drawer: 19rem;
}
html{
  scroll-behavior: smooth;
}
*{
    margin: 0;
    padding: 0;    
}
p{
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.bgWhite{
  background-color: var(--color-white);
}
.bgGreen{
    background-color: var(--color-green)!important;
}
.bgGrayPrimary{
    background-color: var(--color-primary-gray)!important;
}
.bgGraySecundary{
    background-color: var(--color-sidebar-gray)!important;
}
.bgGrayTertiary{
    background-color: var(--color-footer-gray)!important;
}
.overflowxHidden{
  overflow-x: hidden !important;
}

.decorationNone{
  text-decoration: none;
}
.colorDarkGreen{
  color:#429e66;
}
.colorWhite{
  color:#ffff!important;
}
.colorGreen{
    color: var(--color-green)!important;
}
.colorGrayPrimary{
    color: var(--color-primary-gray)!important;
}
.colorGraySecundary{
    color: var(--color-sidebar-gray)!important;
}
.colorGrayTertiary{
    color: var(--color-footer-gray)!important;
}

.jcCenter{
    justify-content: center !important;
}
.textCenter{
    text-align: center;
}

.jcText{
  text-align: justify;
}
.paddingAll{
  padding: 20px;
}
.borderNone{
    border-radius: 0 !important;
    border: none;
    box-shadow: none !important;
}

.widthMax{
  width: 100%;
}

.paragraph{
  text-indent: 20px;
}

.swiperContainerWH{
  height: 300px;
}

.avatarWH{
  width: var(--size-avatar)!important ;
  height: var(--size-avatar)!important ;
}
.MuiTypography-h2 {
  font-size: 2.5rem !important;
  font-family: var(--font-primary) !important;
  font-weight: 800 !important;
  line-height: 1.235 !important;
  letter-spacing: 0.00735em !important;
}

.MuiTypography-h3 {
  font-size: 2rem !important;
  font-family: var(--font-primary) !important;
  font-weight: 800 !important;
  line-height: 1.235 !important;
  letter-spacing: 0.00735em !important;
}
.MuiTypography-body1 {
  font-size: 1.5rem !important;
  font-family: var(--font-primary) !important;
  font-weight: 800 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00735em !important;
}
.MuiTypography-body2 {
  font-size: 1.4rem !important;
  font-family: var(--font-secundary)!important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.fontTopic{
  font-family: var(--font-primary) !important;
  font-weight: 700 !important;
}

.fontText{
  font-family: var(--font-secundary) !important;
  font-weight: 200 !important;
}
@media (min-width: 0px) and (max-width: 600px){
  
}

@media (min-width: 601px) and (max-width: 960px){
  
}
@media (min-width: 961px) and (max-width: 1080px){
  .marginDrawer{
    margin-left: var(--width-drawer);
  }
  .appBarNone{
    display: none!important;
  }
}
@media (min-width: 1081px) and (max-width: 1920px) {
  .marginDrawer{
    margin-left: var(--width-drawer);
  }
  .appBarNone{
    display: none!important;
  }
}
@media (min-width: 1921px) {
  .marginDrawer{
    margin-left: var(--width-drawer);
  }
  .appBarNone{
    display: none!important;
  }
}