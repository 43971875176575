@media (max-width: 600px) {
  
}
@media (min-width: 601px) and(max-width: 960){
  
}
@media (max-width: 960px) and(max-width: 1080){
  .buttonNone{
    display:none!important;
  }
}
@media (max-width: 1081px) and(max-width: 1920){
  .buttonNone{
    display:none!important;
  }
}
@media (min-width:1921px){
  .buttonNone{
    display:none!important;
  }
}