
.cardStyle div img{
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.titleDistance{
  padding: 5px 0 5px 0;
}
div.cardStyle{
  margin-right: auto;
  margin-left: auto;
}

@media (max-width:600px) {

  .cardStyle{
    width: 300px;
    height: 315px;
    background-color: var(--color-primary-gray) ;
    text-align: center;
    border-radius: 5%;
  }

  .muiWH{
    padding-top: 20px;
    width: 270px !important;
    height: 210px !important;
  }

  .figmaWH{
    width: 260px!important;
   height: 260px !important;
  }
  
.reactWH{
  width: 225px !important;
  height: 260px !important;
}

.muiPadTop{
   padding-top: 25px;
}
.jsWH{
    width: 230px!important;
    height: 256px !important;

}
.cssWH{
  width: 225px !important;
  height: 257px !important;

}
.htmlWH{
  width: 230px!important;
  height: 255px !important;

}
.nodeWH{
  width: 230px !important;
  height: 260px !important;
}

}


@media (min-width: 601px) and (max-width:960px)  {
  .cardStyle{
    width: 230px;
    height: 315px;
    background-color: var(--color-primary-gray) ;
    text-align: center;
    border-radius: 5%;
  }
  .muiWH{
    height: 220px;
    width: 210px !important;
    padding-top: 40px;
  }
  .figmaWH{
    padding-top: 20px;
    height: 210px!important;
    width: 210px!important;
  }
  
  .reactWH{
    padding-top: 10px;
    width: 200px !important;
    height: 230px !important; 
  }

  .jsWH{
    padding-top: 10px;
    height: 240px!important;
    width: 215px!important;

  } 
  .cssWH{
    padding-top: 10px;
    height: 240px!important;
    width: 210px!important;
  }
  .htmlWH{
    padding-top: 10px;
    height: 240px!important;
    width: 218px!important;
  }
  .nodeWH{
    padding-top: 10px;
    height: 235px !important;
    width: 210px !important;
  }

}
@media (min-width: 961px) and (max-width:1280px)  {
  .cardStyle{
    width: 200px;
    height: 280px;
    background-color: var(--color-primary-gray);
    text-align: center;
    border-radius: 5%;
    margin-bottom: 20px;
  }
  .muiWH{
    padding-top: 40px;
    height: 145px !important;
    width: 180px !important;
  }
  .figmaWH{
    padding-top: 10px;
    width: 190px!important;
    height: 190px!important;
  }
  .reactWH{
    width: 180px !important;
    height: 210px !important;
  }
  .jsWH{
    width: 180px !important;
    height: 210px !important;
  
  }
  .cssWH{
    width: 180px !important;
    height: 210px !important;
  
  }
  .htmlWH{
    width: 180px !important;
    width: 190px!important;
  
  }
  .nodeWH{
    padding-top:10px;
    width: 180px !important;
    height: 200px !important;
  } 

}
@media (min-width:1281px) and ( max-width:1629px){
  .cardStyle{
    width: 230px;
    height: 300px;
    background-color: var(--color-primary-gray) ;
    text-align: center;
    border-radius: 5%;
    margin-bottom: 20px;
    align-content: center;
  }
  .muiWH{
    padding-top: 35px;
    width: 190px !important;
    height: 160px !important;
  }
  .figmaWH{
    width: 220px!important;
    height: 220px!important;
  }
  .reactWH{
    width: 200px !important;
    height: 230px !important;
  }
  .jsWH{
      width: 210px !important;
      height: 230px !important;
  
  }
  .cssWH{
    width: 210px !important;
    height: 230px !important;
  
  }
  .htmlWH{
    width: 210px !important;
    width: 212px!important;
  
  }
  .nodeWH{
    padding-top:10px;
    height: 220px !important;
    width: 210px !important;
  } 

}
@media (min-width:1630px) and ( max-width:1980px){
  .cardStyle{
    width: 270px;
    height: 300px;
    background-color: var(--color-primary-gray) ;
    text-align: center;
    border-radius: 5%;
    margin-bottom: 20px;
    align-content: center;
  }
  .muiWH{
    padding-top: 30px;
    width: 220px !important;
    height: 180px !important;
  }
  .figmaWH{
    width: 230px!important;
    height: 230px!important;
  }
  .reactWH{
    width: 210px !important;
    height: 230px !important;
  }
  .jsWH{
      width: 215px !important;
      height: 230px !important;
  
  }
  .cssWH{
    width: 215px !important;
    height: 230px !important;
  
  }
  .htmlWH{
    width: 215px !important;
    width: 212px!important;
  
  }
  .nodeWH{
    padding-top:10px;
    width: 200px !important;
    height: 220px !important;
  } 

}

@media (min-width: 1981px) {
  .cardStyle{
    width: 350px;
    height: 350px;
    background-color: var(--color-primary-gray) ;
    text-align: center;
    border-radius: 5%;
    margin-bottom: 20px;
    align-content: center;
  }
  .muiWH{
    padding-top: 20px;    
    width: 290px !important;
    height: 240px !important;
  }
  .figmaWH{
    width: 270px!important;
    height: 270px!important;
  }
  .reactWH{
    width: 240px !important;
    height: 270px !important;
  }
  .jsWH{
    width: 250px !important;
    height: 270px !important;
  
  }
  .cssWH{
    width: 250px !important;
    height: 270px !important;
  
  }
  .htmlWH{
    height: 270px !important;
    width: 250px !important;
  
  }
  .nodeWH{
    padding-top:20px;
    width: 250px !important;
    height: 270px !important;
  } 

}
