.b1ImgWH{
  height: 200px;
  width: 340px;
}
.cardWH{
  width: 340px;
  background-color: var(--color-primary-gray)!important;
}
@media (max-width: 768px) { 
    
  
  .fontExp{
      font-family: var(--font-primary) !important;
      font-weight: 700 !important;
      padding-bottom: 8px;
  }
  
}

@media (min-width: 769px) { 
  

}